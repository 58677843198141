<div class="painel-tv" style="background-image: url('assets/img/sampasky.png')" [ngStyle]="{
    'background-image': 'url('+ tv?.bgImage +')'}" >
  <div class="painel-tv__opacity" [ngStyle]="{
      background:
        'linear-gradient(180deg, ' +
        hexToRGBA(tv.bgImageColor, +(tv.bgImageAlpha) / 100) +
        ', ' +
        hexToRGBA(tv.bgImageColor, +(tv.bgImageAlpha) / 100) +
        ')'
    }"></div>
  <div class="painel-tv__wrapper" [ngStyle]="{
    'background-color': tv.bgImage ? 'transparent' : tv.bgColor,
  }">
    <div class="painel-tv__header" [ngStyle]="{
      color: tv?.textColor,
    }">
      <h1>{{ tv.nome }}</h1>
    </div>
    <audio id="audio" autostart="false"></audio>
    <div class="painel-tv__content" #clickArea (click)="clickMe()">
      <div class="painel-tv__content-sidebar">
        <div class="painel-tv__queue-ticket" [ngStyle]="{
            color: tv?.textColor,
          }">
          <label>Senha</label>
          <div>{{ model.senha }}</div>
        </div>
        <div class="painel-tv__estimative-time" [ngStyle]="{
            color: tv?.textColor
          }">
          <label *ngIf="!!previsaoAtendimento && previsaoAtendimento > 0">Tempo estimado</label>
          <div *ngIf="!!previsaoAtendimento && previsaoAtendimento > 0">{{previsaoAtendimento}} min</div>
        </div>
        <div class="painel-tv__queue-size" [ngStyle]="{
            color: tv?.textColor
          }">
          <label>Pessoas na fila</label>
          <div>{{countSenhasChamada}}</div>
        </div>
      </div>
      <div class="painel-tv__last-call" [ngStyle]="{
          color: tv?.textColor,
          'border-color': tv?.textColor
        }">
        <div class="painel-tv__last-call-header" [ngStyle]="{
            'border-color': tv?.textColor
          }">
          <label>Últimas chamadas</label>
        </div>
        <div class="painel-tv__last-call-list">
          <div *ngFor="let h of historico | slice : 0 : 6">
            {{ h.senha.senha }}
          </div>
        </div>
      </div>
    </div>
    <div class="painel-tv__footer">
      <marquee class="marquee" *ngIf="avisos?.length > 0">
        <ul>
          <li *ngFor="let aviso of avisos" [ngStyle]="{
              color: tv?.textColor
            }">
            {{ aviso.mensagem }}
          </li>
        </ul>
      </marquee>
    </div>
  </div>
</div>
<!-- <div id="SSOTV" class="painel-tv">
  <audio id="audio" autostart="false"></audio>
  <div class="painel-tv__content">
    <div
      class="tv-senha"
      #clickArea
      (click)="clickMe()"
      [ngStyle]="{
        color: empresa?.textColor,
        'background-color': empresa?.bgColor,
        'border-color': empresa?.bgColor
      }"
    >
      <div class="tv-senha__content">
        <div class="tv-senha__senha" id="mesa">
          <strong>{{ model.senha }}</strong> -
          {{ getNomeChamada(model.tipoChamada) }} {{ model.mesa }}
        </div>
        <div class="tv-senha__nome" id="senha">
          {{ model.nome ? model.nome : "" }}
        </div>
      </div>
    </div>

    <div class="ultimas-chamdas">
      <h3>Últimas chamadas</h3>
      <ul>
        <li
          *ngFor="let h of historico | slice : 0 : 4"
          style="border: 2px solid #ccc; border-radius: 20px"
        >
          <span class="u-senha">{{ h.senha.senha }}</span>
          <span class="u-mesa"
            >{{ getNomeChamada(model.tipoChamada) }} {{ model.mesa }}</span
          >
          <span class="u-nome">{{ h.senha.nome }}</span>
        </li>
      </ul>
    </div>
    <div class="avisos">
      <marquee class="marquee" *ngIf="avisos?.length > 0">
        <ul>
          <li *ngFor="let aviso of avisos">{{ aviso.mensagem }}</li>
        </ul>
      </marquee>
    </div>
  </div>
</div> -->