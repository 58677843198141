<div id="wrapper">

  <!-- Menu lateral -->

  <!-- <menu-component *ngIf="usuarioLogado()"></menu-component> -->

  <div id="page-wrapper" class="gray-bg" [ngClass]="!usuarioLogado()? 'page-wrapper-login': 'page-wrapper-login'">
    <div class="row border-bottom" *ngIf="usuarioLogado()" id="SIDE_NOTV">
      <nav class="navbar navbar-static-top white-bg" role="navigation" style="margin-bottom: 0">
        <ul class="nav navbar-top-links navbar-center text-center">
          <li>
            <a>
              <strong><span class="m-r-sm text-muted welcome-message" *ngIf="FRANQUIA_SESSION">
                  <i class="fa fa-building"></i> {{FRANQUIA_SESSION?.nome | uppercase}}</span></strong>
            </a>
          </li>
          <li class="navbar-right">
            <a [routerLink]="['/login']">
              <i class="fa fa-sign-out"></i> Sair
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <router-outlet></router-outlet>
    <div class="footer" id="FOOTER_NOTV">
      <div class="pull-right">
        <strong>v6.12.10</strong>
      </div>
      <div>
        Smartline &copy; 2024
      </div>
    </div>

  </div>
</div>
