import { AfterContentInit, Component, ElementRef, EventEmitter, Injector, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractComponent, AudioHelper, Aviso, Config, Empresa, Franquia, PublicAvisoService, PublicEmpresaService, PublicFranquiaService, PublicSenhaService, PublicTVService, SocketService, TV, YoutubeHelper } from 'lib-smart-core';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-tv',
  styleUrls: ['./app-tv.component.css'],
  templateUrl: './app-tv.component.html'
})
export class AppTVComponent extends AbstractComponent implements OnInit, AfterContentInit, OnDestroy {

  public _incognito: Boolean = false;

  public reframed: Boolean = false;
  private youtubeHelper: YoutubeHelper;
  private audioHelper: AudioHelper;

  @ViewChild('btnAtualizar') btnAtualizar: ElementRef;
  @ViewChild('btnOnline') btnOnline: ElementRef;
  @ViewChild('btnAvisos') btnAvisos: ElementRef;

  // only LAYOUT_5
  previsaoAtendimento: number = 0;
  countSenhasChamada: number = 0;

  idTV: string;
  idGrupo: string = null;
  idEmpresa: string = null;
  idFranquia: string = null;

  avisos: Aviso[];
  historico: any[];
  franquiaAdminstradaProGrupo: Boolean = false;

  tmpSENHA: string;
  tmpMESA: string;
  tmpVOZ: string;
  tmpCHAMADA: string;
  tmpNOME: string;
  tmpSIGLAMESA: string;

  video;
  urlVideoLive: string;
  playlist_id: string;

  // tela
  model: any;

  tv: TV;
  empresa: Empresa;
  franquia: Franquia;
  config: Config;
  showNome: boolean = false;

  IS_LIVE: Boolean = false;

  constructor(
    injector: Injector,
    private socketService: SocketService,
    private franquiaService: PublicFranquiaService,
    private tvService: PublicTVService,
    private senhaService: PublicSenhaService,
    private avisoService: PublicAvisoService,
    private empresaService: PublicEmpresaService,
    private ngZone: NgZone,
  ) {
    super(injector);

    if (document.getElementById("page-wrapper")) {
      document.getElementById("page-wrapper").classList.add("page-wrapper-login");
    }
    if (document.getElementById("page-wrapper")) {
      document.getElementById("page-wrapper").style.padding = "0 0";
    }
    if (document.getElementById("MENU_NOTV")) {
      document.getElementById("MENU_NOTV").remove();
    }
    if (document.getElementById("SIDE_NOTV")) {
      document.getElementById("SIDE_NOTV").remove();
    }
    if (document.getElementById("FOOTER_NOTV")) {
      document.getElementById("FOOTER_NOTV").remove();
    }

    this.audioHelper = new AudioHelper();

  }

  ngOnInit() {
  }

  ngAfterContentInit(): void {
    this.model = { senha: 'N001', mesa: '', tipoChamada: '' };
    this.idTV = super.getParam('idTV');
    if (this.idTV) {
      console.log('loading com idTV');
      this.loadTV();
    }
    this._incognito = super.getParam('incognito') || false;
  }

  clickMe() {
    console.log('click area okay');
  }

  override ngOnDestroy(): void {
    this.socketService.disconnect();
  }

  loadTV() {
    this.tvService.getByIdPublic(this.idTV).subscribe(
      (tv: TV) => {
        this.tv = tv;
        this.idEmpresa = tv.empresa._id;
        this.idFranquia = tv.franquia._id;
        this.showNome = tv.showNome;
        this.loadEmpresa();
        this.loadFranquia();

        /**
         * Adicionar aqui as demais TVs com Youtube
         */
        if (tv.layout === 'DEFAULT') {
          this.loadURLPlaylist();
        }

        /**
         * Load previsao de atendimento e count de senhas chamadas
         */
        this.loadPrevisaodeAtendimentoECountSenhasChamadas();
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadEmpresa() {
    this.empresaService.getPublic(this.idEmpresa).subscribe(
      (empresa: Empresa) => {
        this.empresa = empresa;
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadFranquia() {
    this.franquiaService.getById(this.idFranquia).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia;
        if (franquia.grupo && franquia.grupo._id) {
          this.franquiaAdminstradaProGrupo = true;
          this.idGrupo = franquia.grupo._id;
        }
        //this.loadConfig();
        this.loadHistorico();
        this.loadAvisos();
        this.configSocket();
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadHistorico() {
    if (!this._incognito) {
      const idFilas = ((!!this.tv.filas && this.tv.filas.length > 0) ? this.tv.filas.map(fila => fila._id) : []);
      this.senhaService.historicoChamadasPublic(this.idFranquia, idFilas).subscribe({
        next: (historico: any[]) => {
          this.historico = historico;
          if (!this.tmpSENHA && historico && historico.length > 0) {
            this.model.senha = historico[0].senha.senha;
            this.model.nome = historico[0].senha?.nome;
            const tipoChamada = historico[0].senha.fila.tipoChamada;
            if (tipoChamada !== '') {
              this.model.tipoChamada = historico[0].fila.tipoChamada;
              this.model.mesa = historico[0].mesa.nome;
            }
          }
        },
        error: (err) => this.alertService.error(err.error.message)
      });
    }
  }

  loadAvisos() {
    if (this.franquiaAdminstradaProGrupo) {
      this.avisoService.getListAvisosFranquia(this.idFranquia).subscribe(
        avisosGrupo => {
          this.avisos = (avisosGrupo);
        }, err => this.alertService.error(err.error.message)
      );
    } else {
      this.avisoService.getListAvisosFranquia(this.idFranquia).subscribe(
        avisosFranquia => {
          this.avisos = (avisosFranquia);
        }, err => this.alertService.error(err.error.message)
      );
    }
  }

  loadURLPlaylist() {
    this.youtubeHelper = new YoutubeHelper(this.ngZone);

    let url = this.tv.playlist.url;

    var listid = url.match("list=([a-zA-Z0-9\-\_]+)&?");
    if (listid) {
      this.reframed = false;
      this.playlist_id = listid[1];
      let playerVars = {
        'loop': 1, 'autoplay': 1, 'controls': 1, 'listType': 'playlist', 'list': this.playlist_id, "enablejsapi": 1
      };

      let change = new EventEmitter();
      change.subscribe((e) => {
        this.onPlayerStateChange(e);
      });

      let ready = new EventEmitter();
      ready.subscribe((e) => {
        this.onPlayerReady(e);
      });

      this.youtubeHelper.setupPlayer({
        change: change,
        ready: ready,
      }, {}, "", playerVars);

    }
  }

  onBtnOnline() {
    var videoid = this.urlVideoLive.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
    if (videoid != null) {
      let video_live_id = videoid[1];
      this.IS_LIVE = true;
      // this.player.loadVideoById(video_live_id);
      // this.player.cuePlaylist({ listType: 'playlist', list: this.playlist_id });
      this.youtubeHelper.setVolume(75);
      this.youtubeHelper.playVideoById(video_live_id);
    }
  }

  onBtnAvisos() {
    this.loadAvisos();
  }

  loadPrevisaodeAtendimentoECountSenhasChamadas() {
    if (this.tv.layout === 'LAYOUT_5') {
      const idFilas = ((!!this.tv.filas && this.tv.filas.length > 0) ? this.tv.filas.map(fila => fila._id) : []);
      forkJoin([
        this.senhaService.getCountSenhasNaChamada(this.idEmpresa, this.idFranquia, idFilas),
        this.senhaService.getPrevisaoDeAtendimentoChamada(this.idEmpresa, this.idFranquia, idFilas)
      ]).subscribe(
        ([countSenhasChamada, previsaoAtendimento]) => {
          this.countSenhasChamada = countSenhasChamada;
          this.previsaoAtendimento = previsaoAtendimento;
        }
      );
    }
  }

  onBtnAtualizar() {
    this.model.senha = this.tmpSENHA;
    this.model.tipoVoz = this.tmpVOZ || '';
    this.model.tipoChamada = this.tmpCHAMADA || '';
    this.model.mesa = this.tmpMESA || '';
    this.model.nome = this.tmpNOME || '';
    this.model.sigla = this.tmpSIGLAMESA || '';
    this.playAudio();

    if (this._incognito) {
      if (!this.historico) {
        this.historico = new Array<any>();
      }
      if (this.historico.length === 5) {
        this.historico.splice(0, 1);
      }
      this.historico.push({ senha: { senha: this.tmpSENHA }, mesa: { nome: this.tmpMESA } });
    } else {
      this.loadHistorico();
      // nãa tem como mais usar o dado do historico vindo do socket pq a tv tem filas dinamicas
      // this.historico = this.event_senha_data.historico;
      // if (!this.tmpSENHA && this.historico && this.historico.length > 0) {
      //   this.model.senha = this.historico[0].senha.senha;
      //   this.model.nome = this.historico[0].senha?.nome;
      //   const tipoChamada = this.historico[0].senha.fila.tipoChamada;
      //   if (tipoChamada !== '') {
      //     this.model.tipoChamada = this.historico[0].fila.tipoChamada;
      //     this.model.mesa = this.historico[0].mesa.nome;
      //   }
      // }
    }
    this.loadPrevisaodeAtendimentoECountSenhasChamadas();
  }

  onBtnAtualizar2() {
    this.model.senha = this.tmpSENHA;
    this.model.tipoVoz = this.tmpVOZ || '';
    this.model.tipoChamada = this.tmpCHAMADA || '';
    this.model.mesa = this.tmpMESA || '';
    this.model.nome = this.tmpNOME || '';
    this.model.sigla = this.tmpSIGLAMESA || '';
    this.playAudio();
    this.loadHistorico();
    if (this._incognito) {
      if (!this.historico) {
        this.historico = new Array<any>();
      }
      if (this.historico.length === 5) {
        this.historico.splice(0, 1);
      }
      this.historico.push({ senha: { senha: this.tmpSENHA }, mesa: { nome: this.tmpMESA } });
    }
  }

  playAudio() {
    if (!!this.tv.showAlerta && this.tv.showAlerta === true) {
      this.audioHelper.playAlert();
    } else {
      this.audioHelper.play(this.model.senha, this.model.mesa, this.model.tipoVoz, this.model.tipoChamada, this.youtubeHelper);
    }
  }

  event_senha_data: any;

  configSocket(): void {
    this.socketService.connect();

    const hasFilasEspecificas = !!this.tv.filas && this.tv.filas.length > 0;

    if (this._incognito) {
      this.socketService.listen('event_senha_incognito_' + this.idFranquia).subscribe(
        (data: any) => {
          this.event_senha_data = data;
          this.tmpSENHA = data.senha;
          this.tmpMESA = '';
          this.tmpVOZ = data.fila.tipoVoz;
          this.tmpCHAMADA = '';
          this.tmpNOME = '';
          this.tmpSIGLAMESA = data.fila.nome;
          if (hasFilasEspecificas) {
            const idFilas = this.tv.filas.map(fila => fila._id);
            if (idFilas.includes(data.fila._id)) {
              this.btnAtualizar.nativeElement.click();
            }
          } else {
            this.btnAtualizar.nativeElement.click();
          }
        }
      )
    } else {
      this.socketService.listen('event_senha_' + this.idFranquia).subscribe(
        (data: any) => {
          this.event_senha_data = data;
          this.tmpSENHA = data.senha;
          this.tmpMESA = data.mesa;
          this.tmpVOZ = data.fila.tipoVoz;
          this.tmpCHAMADA = data.fila.tipoChamada;
          this.tmpNOME = data.nome;
          this.tmpSIGLAMESA = data.fila.nome;
          if (hasFilasEspecificas) {
            const idFilas = this.tv.filas.map(fila => fila._id);
            if (idFilas.includes(data.fila._id)) {
              this.btnAtualizar.nativeElement.click();
            }
          } else {
            this.btnAtualizar.nativeElement.click();
          }
        }
      )
      this.socketService.listen('event_rechamar_senha_' + this.idFranquia).subscribe(
        (data) => {
          this.tmpSENHA = data.senha;
          this.tmpMESA = data.mesa;
          this.tmpVOZ = data.fila.tipoVoz;
          this.tmpCHAMADA = data.fila.tipoChamada;
          this.tmpNOME = data.nome;
          this.tmpSIGLAMESA = data.fila.nome;
        }
      )
      this.socketService.listen('event_online_' + (this.franquiaAdminstradaProGrupo ? this.idGrupo : this.idFranquia)).subscribe(
        (data) => {
          this.urlVideoLive = data.url;
          this.btnOnline.nativeElement.click();
        }
      );
    }

    this.socketService.listen('event_avisos_' + this.idFranquia).subscribe(
      (data) => {
        this.btnAvisos.nativeElement.click();
      }
    );

  }

  onPlayerReady(event) {
    if (!this.reframed) {
      this.reframed = true;
      this.youtubeHelper.reframe();
    }
    event.target.setVolume(50);
    event.target.playVideo();
  }

  onPlayerStateChange(event) {
    switch (event.data) {
      case window['YT'].PlayerState.PLAYING:
        if (this.cleanTime(event.target) == 0) {
          console.log('started ' + this.cleanTime(event.target));
        } else {
          console.log('playing ' + this.cleanTime(event.target))
        };
        break;
      case window['YT'].PlayerState.PAUSED:
        if (event.target.getDuration() - event.target.getCurrentTime() != 0) {
          console.log('paused' + ' @ ' + this.cleanTime(event.target));
        };
        break;
      case window['YT'].PlayerState.ENDED:
        console.log('ended ');
        if (this.IS_LIVE === true) {
          this.youtubeHelper.setVolume(50);
          event.target.loadPlaylist({ list: this.playlist_id, listType: 'playlist' });
          this.IS_LIVE = false;
        }
        break;
    };
  }

  // utility
  cleanTime(player) {
    return Math.round(player.getCurrentTime())
  };

}
